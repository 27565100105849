.gantt_grid_container_tasks {
	outline: 0.5px solid var(--color-outline);
}

.gantt_task_row {
	display: flex;
	outline: 0.5px solid var(--color-outline);
	text-align: center;
	height: var(--cell-height);
	border: none;
}

.input,
.p {
	width: 105px;
	border: none;
	outline: none;
	background: none;
}

.button {
	line-height: 0px;
	color: var(--color-orange);
	background: none;
	border-radius: 5px;
	border: none;
	transition: all 0.2s ease;
}

.button:hover,
.button:focus {
	outline: none;
	transform: scale(1.3);
}
