.add_task_duration {
	margin-right: 10px;
	margin-bottom: 10px;
	border-radius: 5px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
}

.fieldset_container {
	display: flex;
	align-items: center;
	flex-direction: row;
	flex-wrap: wrap;
}

.fieldset {
	display: flex;
	align-items: center;
	border: none;
	padding: 5px 7px;
}

.fieldset_label {
	margin-right: 10px;
}

.input[type='date'] {
	padding: 10px 5px;
}
