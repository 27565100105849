.main {
	min-height: 100vh;
	padding: 1rem 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	font-size: 1.5rem;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

nav {
	display: flex;
	margin: 0 auto 0px;
	gap: 10px;
	align-items: center;
}
nav h1 {
	margin-right: auto;
}
nav a {
	color: #333;
	padding: 4px 10px;
}

nav a.active {
	color: white;
	background: #333;
	text-decoration: none;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
