.gantt_grid_container__time {
	display: grid;
	overflow-x: auto;
	outline: 0.5px solid var(--color-outline);
}

.taskDuration {
	position: absolute;
	height: calc(var(--cell-height) / 3);
	top: calc(var(--cell-height) / 3);
	z-index: 1;
	background: linear-gradient(90deg, var(--color-primary-light) 0%, var(--color-primary-dark) 100%);
	border-radius: 2px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
	cursor: move;
}

.taskDuration:focus {
	outline: 1px solid black;
}

.dragging {
	opacity: 0.5;
}

.calculation_row {
	height: var(--cell-height);
	outline: 0.5px solid var(--color-outline);
}

.left_box {
	position: absolute;
	height: 38px;
	width: 10px;
	background-color: green;
	border-radius: 5px;
	cursor: col-resize;
	top: 1px;
	left: 1px;
}

.right_box {
	position: absolute;
	height: 38px;
	width: 10px;
	background-color: red;
	border-radius: 5px;
	cursor: col-resize;
	top: 1px;
	right: 1px;
}
